import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="https://media.licdn.com/dms/image/C4D0BAQFKvK_5LCQucg/company-logo_200_200/0/1630564038599/thunderbyte_ai_logo?e=2147483647&v=beta&t=TpAVijZrEn-cqFYQfaI7-7MG3ptmqBLo_24O0hWPDW0"
								alt="facebook"
								className="work-image"
							/>
							<div className="work-title">Thunderbte.ai</div>
							<div className="work-subtitle">
								Frontend Developer
							</div>
							<div className="work-duration">2022 - 2024</div>
						</div>

						<div className="work">
							<img
								src="https://yt3.googleusercontent.com/ytc/AIdro_n6srgUk2W4LZe43KILKTOwUKRrBTND6YOAODbFLQ=s900-c-k-c0x00ffffff-no-rj"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Gadero</div>
							<div className="work-subtitle">
								Forklift operator
							</div>
							<div className="work-duration">2021 - Present</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
