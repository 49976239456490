const INFO = {
	main: {
		title: "Kars Talens | Portfolio",
		name: "Kars talens",
		email: "contact@karstalens.nl",
		logo: "https://i.imgur.com/rpXacEX.jpeg",
	},

	socials: {
		twitter: "https://twitter.com/KarsTalens",
		github: "https://github.com/TheOnly3aq",
		linkedin: "https://www.linkedin.com/in/kars-talens-676a241a5/",
		instagram: "https://instagram.com/",
		stackoverflow:
			"https://stackoverflow.com/users/17327325/vroom-vroom-man",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Frontend developer, future Full-Stack web developer and car lover.",
		description:
			"I am a 19 year old software development student based in Groningen, Netherlands. I've been focussing on frontend development over the past couple of years, and recently I've also really gotten into backend development, especially Django. ",
	},

	about: {
		title: "I’m Kars Talens. I live in Groningen, where I code my way through life.",
		description:
			"Hey there, glad you decided to take a look around at my website. My name is Kars Talens and I'm 19 years old. I study Software Development at Alfa College in Groningen (3rd year) and I've been making websites since high school, recently I'v started a school project, where I'm learning backend development, and so far I'm enjoying it a lot. Are you interested in getting in touch with me? Head over to my contact page.",
	},

	articles: {
		title: "My articles",
		description:
			"Whenever there's something on my mind, I'll come here to write it to all of you.",
	},

	projects: [
		{
			title: "Snackbezorgd.nl",
			description:
				"Snackbezorgd.nl is a fictional company me and my group made for a big school project. It's build with ReactJS and Django.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			logo3: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/css/css.png",
			linkText: "View Project",
			link: "https://snackbezorgd.karstalens.nl/",
		},

		{
			title: "Vroom's Miscellaneous Scripts",
			description:
				"Vroom's Miscellaneous Scripts (VMS) is a FiveM Lua Script I made to solve the high resource count in fivem servers. Read more on the github repo.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/lua/lua.png",
			logo2: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1280px-HD_transparent_picture.png",
			logo3: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1280px-HD_transparent_picture.png",
			linkText: "View Project",
			link: "https://github.com/TheOnly3aq/Misc",
		},

		{
			title: "Dolex Watches",
			description:
				"Dolex is as you can see on the website, a Rolex knockoff. This was a school project where we had to use php arrays in a website.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/php/php.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			logo3: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/css/css.png",
			linkText: "View Project",
			link: "https://github.com/TheOnly3aq/dolex",
		},

		{
			title: "FBI Fugitive Search Engine",
			description:
				"The FBI Fugitive Search Engine is another school project, we had to use an external API and integrate that into a ReactJS frontend.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/css/css.png",
			logo3: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com/TheOnly3aq/fbi",
		},

		{
			title: "Irritantewebsite",
			description:
				"Irritante website (transtaled annoyingwebsite) was one of my first school projects. I made this in Javascript, as the assignement was to create the most anoying website possible using plain HTML, CSS & JS.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			logo2: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/css/css.png",
			logo3: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/TheOnly3aq/irritantewebsite",
		},
	],
};

export default INFO;
